var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "mx-auto d-flex flex-column",
      attrs: { light: "", elevation: "3" },
    },
    [
      _vm.brandStateCategoryColor
        ? _c(
            "v-tooltip",
            {
              attrs: { bottom: "", color: _vm.brandStateCategoryColor },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function (ref) {
                      var on = ref.on
                      var attrs = ref.attrs
                      return [
                        _c(
                          "v-system-bar",
                          _vm._g(
                            _vm._b(
                              {
                                attrs: {
                                  color: _vm.brandStateCategoryColor,
                                  height: "6",
                                },
                              },
                              "v-system-bar",
                              attrs,
                              false
                            ),
                            on
                          )
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                3019154328
              ),
            },
            [
              _vm.brandStateCategoryIcon
                ? _c("v-icon", { attrs: { dark: "", left: "" } }, [
                    _vm._v(_vm._s(_vm.brandStateCategoryIcon)),
                  ])
                : _vm._e(),
              _vm.brandStateCategory
                ? [
                    _vm.brandStateCategory.shortDescription
                      ? _c("span", [
                          _vm._v(
                            _vm._s(_vm.brandStateCategory.shortDescription)
                          ),
                        ])
                      : _vm.brandStateCategory.key
                      ? _c("span", [_vm._v(_vm._s(_vm.brandStateCategory.key))])
                      : _vm._e(),
                  ]
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _vm.loading
        ? [
            _c("v-skeleton-loader", {
              attrs: {
                type: "list-item-avatar-two-line, divider, list-item-two-line@4",
              },
            }),
          ]
        : [
            _c(
              "v-toolbar",
              {
                attrs: {
                  flat: "",
                  light: "",
                  rounded: "lg",
                  color: "transparent",
                },
              },
              [
                _vm.brandData.registrationOfficeCode &&
                _vm.brandData.registrationOfficeCode.key &&
                _vm.brandData.registrationOfficeCode.description
                  ? _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _vm.brandData.registrationOfficeCode &&
                                  _vm.brandData.registrationOfficeCode.key
                                    ? _c(
                                        "v-img",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              attrs: {
                                                src: _vm.$braendz.registrationOfficeCodeFlag(
                                                  _vm.brandData
                                                    .registrationOfficeCode.key
                                                ),
                                                contain: "",
                                                "max-height": "40",
                                                "max-width": "40",
                                              },
                                            },
                                            "v-img",
                                            attrs,
                                            false
                                          ),
                                          on
                                        )
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2770852831
                        ),
                      },
                      [
                        _vm.brandData.registrationOfficeCode
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.brandData.registrationOfficeCode
                                    .description
                                )
                              ),
                            ])
                          : _vm._e(),
                      ]
                    )
                  : _vm.brandData.registrationOfficeCode &&
                    _vm.brandData.registrationOfficeCode.key
                  ? _c("v-img", {
                      attrs: {
                        src: _vm.$braendz.registrationOfficeCodeFlag(
                          _vm.brandData.registrationOfficeCode.key
                        ),
                        contain: "",
                        "max-height": "40",
                        "max-width": "40",
                      },
                    })
                  : _vm._e(),
                _c(
                  "v-toolbar-title",
                  { staticClass: "ml-3" },
                  [
                    _c(
                      "v-list-item",
                      { attrs: { "two-line": "" } },
                      [
                        _c(
                          "v-list-item-content",
                          [
                            !_vm.brandData.brandType ||
                            _vm.brandData.brandType.key !== "Figurative"
                              ? _c(
                                  "TruncateTooltip",
                                  {
                                    attrs: {
                                      label: _vm.brandData.name,
                                      top: "",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-list-item-title",
                                      { staticClass: "text-h5 text-truncate" },
                                      [
                                        _vm._v(
                                          " " + _vm._s(_vm.brandData.name) + " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _c(
                                  "v-list-item-title",
                                  [_c("v-icon", [_vm._v("$no-text")])],
                                  1
                                ),
                            _vm.brandData.brandType &&
                            _vm.brandData.brandType.description
                              ? _c("v-list-item-subtitle", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.brandData.brandType.description
                                      ) +
                                      " "
                                  ),
                                ])
                              : _vm.brandData.brandType
                              ? _c("v-list-item-subtitle", [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.brandData.brandType.key) +
                                      " "
                                  ),
                                ])
                              : _c("v-list-item-subtitle", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("brand.tile.valueNotAvailable")
                                      ) +
                                      " "
                                  ),
                                ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("v-spacer"),
                _vm.brand
                  ? _c("BrandDetailsPopup", {
                      attrs: { id: _vm.brandId },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function (ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      { attrs: { text: "" } },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c("v-icon", { attrs: { left: "" } }, [
                                      _vm._v("fa-light fa-memo-circle-info"),
                                    ]),
                                    _vm._v(" Details "),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3016226215
                      ),
                    })
                  : _vm._e(),
                _vm.canAddToBookmarks || _vm.canRemoveFromBookmarks
                  ? _c(
                      "v-menu",
                      {
                        attrs: { rounded: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function (ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        { attrs: { icon: "" } },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _c("v-icon", [
                                        _vm._v(
                                          "fa-light fa-ellipsis-stroke-vertical"
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          713453957
                        ),
                      },
                      [
                        _c(
                          "v-list",
                          [
                            _vm.canAddToBookmarks
                              ? _c(
                                  "v-list-item",
                                  {
                                    attrs: { disabled: !_vm.userAccount },
                                    on: { click: _vm.addToBookmarks },
                                  },
                                  [
                                    _c(
                                      "v-list-item-icon",
                                      [
                                        _c("v-icon", [
                                          _vm._v("fa-solid fa-star"),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c("v-list-item-title", [
                                      _vm._v(
                                        _vm._s(_vm.$t("brand.tile.bookmarkAdd"))
                                      ),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.canRemoveFromBookmarks
                              ? _c(
                                  "v-list-item",
                                  {
                                    attrs: { disabled: !_vm.userAccount },
                                    on: {
                                      click: function ($event) {
                                        _vm.deleteBookmarkConfirmationPopupVisible = true
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "v-list-item-icon",
                                      [
                                        _c("v-icon", [
                                          _vm._v("fa-light fa-star"),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c("v-list-item-title", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("brand.tile.bookmarkRemove")
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c("v-divider", { staticClass: "mx-3" }),
            _c(
              "v-container",
              { attrs: { fluid: "" } },
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { staticClass: "d-flex flex-row" },
                      [
                        _c(
                          "v-card",
                          {
                            attrs: {
                              flat: "",
                              tile: "",
                              color: "transparent",
                              "min-width": "500",
                              "max-width": "500",
                            },
                          },
                          [
                            _c(
                              "v-card-text",
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c("v-col", { attrs: { cols: "6" } }, [
                                      _vm.brandData.brandState &&
                                      _vm.brandData.brandState.description
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "d-block text-truncate body-1",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.brandData.brandState
                                                    .description
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm.brandData.brandState
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "d-block text-truncate body-1",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.brandData.brandState.key
                                                )
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "span",
                                            {
                                              staticClass:
                                                "d-block text-truncate body-1 text--disabled",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "brand.tile.valueNotAvailable"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                      _c(
                                        "span",
                                        { staticClass: "caption d-block" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("brand.tile.state"))
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _vm.brandData.registrationNumber
                                      ? _c("v-col", { attrs: { cols: "6" } }, [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "d-block text-truncate body-1",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.brandData
                                                    .registrationNumber
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "caption d-block" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "brand.tile.registrationNumber"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _vm.brandData.applicationNumber
                                      ? _c("v-col", { attrs: { cols: "6" } }, [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "d-block text-truncate body-1",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.brandData
                                                    .applicationNumber
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "caption d-block" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "brand.tile.applicationNumber"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _c("v-col", { attrs: { cols: "6" } }, [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "d-block text-truncate body-1 text--disabled",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "brand.tile.valueNotAvailable"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "caption d-block" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "brand.tile.registrationNumber"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  [
                                    _c("v-col", { attrs: { cols: "6" } }, [
                                      _vm.brandData.applicationDate
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "d-block text-truncate body-1",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$d(
                                                    new Date(
                                                      _vm.brandData.applicationDate
                                                    ),
                                                    "short"
                                                  )
                                                )
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "span",
                                            {
                                              staticClass:
                                                "d-block text-truncate body-1 text--disabled",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "brand.tile.valueNotAvailable"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                      _c(
                                        "span",
                                        { staticClass: "caption d-block" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "brand.tile.applicationDate"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c("v-col", { attrs: { cols: "6" } }, [
                                      _vm.brandData.registrationDate
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "d-block text-truncate body-1",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$d(
                                                    new Date(
                                                      _vm.brandData.registrationDate
                                                    ),
                                                    "short"
                                                  )
                                                )
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "span",
                                            {
                                              staticClass:
                                                "d-block text-truncate body-1 text--disabled",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "brand.tile.valueNotAvailable"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                      _c(
                                        "span",
                                        { staticClass: "caption d-block" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "brand.tile.registrationDate"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c("v-col", { attrs: { cols: "6" } }, [
                                      _vm.brandData.expiryDate
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "d-block text-truncate body-1",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$d(
                                                    new Date(
                                                      _vm.brandData.expiryDate
                                                    ),
                                                    "short"
                                                  )
                                                )
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "span",
                                            {
                                              staticClass:
                                                "d-block text-truncate body-1 text--disabled",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "brand.tile.valueNotAvailable"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                      _c(
                                        "span",
                                        { staticClass: "caption d-block" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("brand.tile.expiryDate")
                                            )
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c("v-col", { attrs: { cols: "6" } }, [
                                      _vm.brandData.oppositionPeriodEndDate
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "d-block text-truncate body-1",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$d(
                                                    new Date(
                                                      _vm.brandData.oppositionPeriodEndDate
                                                    ),
                                                    "short"
                                                  )
                                                )
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "span",
                                            {
                                              staticClass:
                                                "d-block text-truncate body-1 text--disabled",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "brand.tile.valueNotAvailable"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                      _c(
                                        "span",
                                        { staticClass: "caption d-block" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "brand.tile.oppositionPeriodEndDate"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  [
                                    !_vm.userAccount
                                      ? _c(
                                          "v-col",
                                          { attrs: { cols: "12" } },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: { plain: "" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.$auth.signIn()
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { left: "" } },
                                                  [
                                                    _vm._v(
                                                      "fa-light fa-right-to-bracket"
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "brand.tile.loginMessage"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "caption d-block",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "brand.tile.ownerAddress"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm.brandData.ownerAddress
                                      ? _c("v-col", { attrs: { cols: "12" } }, [
                                          _c(
                                            "span",
                                            { staticClass: "body-1" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$braendz.getAddressLine(
                                                    _vm.brandData.ownerAddress
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "caption d-block" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "brand.tile.ownerAddress"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _c("v-col", { attrs: { cols: "12" } }, [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "body-1 text--disabled",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "brand.tile.valueNotAvailable"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "d-block caption" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "brand.tile.ownerAddress"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-card",
                          {
                            staticClass: "mr-auto",
                            attrs: { flat: "", tile: "", color: "transparent" },
                          },
                          [
                            _c(
                              "v-card-text",
                              [
                                _c(
                                  "v-row",
                                  [
                                    _vm.brandData.niceClasses &&
                                    _vm.brandData.niceClasses.length > 0
                                      ? _c(
                                          "v-col",
                                          { attrs: { cols: "12" } },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "caption d-block",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "brand.tile.niceClasses"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _vm._l(
                                              _vm.brandData.niceClasses,
                                              function (niceClass, index) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: index,
                                                    staticClass: "d-inline",
                                                  },
                                                  [
                                                    niceClass.description
                                                      ? _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              bottom: "",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "activator",
                                                                  fn: function (
                                                                    ref
                                                                  ) {
                                                                    var on =
                                                                      ref.on
                                                                    var attrs =
                                                                      ref.attrs
                                                                    return [
                                                                      _c(
                                                                        "v-chip",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {
                                                                              staticClass:
                                                                                "mr-1 mb-1",
                                                                              attrs:
                                                                                {
                                                                                  small:
                                                                                    "",
                                                                                  outlined:
                                                                                    "",
                                                                                },
                                                                            },
                                                                            "v-chip",
                                                                            attrs,
                                                                            false
                                                                          ),
                                                                          on
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "body-1",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  niceClass.key
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  niceClass.description
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        )
                                                      : _c(
                                                          "v-chip",
                                                          {
                                                            staticClass:
                                                              "mr-1 mb-1",
                                                            attrs: {
                                                              small: "",
                                                              outlined: "",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "body-1",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    niceClass.key
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        )
                                      : _c("v-col", { attrs: { cols: "12" } }, [
                                          _c(
                                            "span",
                                            { staticClass: "caption d-block" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "brand.tile.niceClasses"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "d-block text-truncate body-1 text--disabled",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "brand.tile.valueNotAvailable"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  [
                                    _vm.brandData.viennaClasses &&
                                    _vm.brandData.viennaClasses.length > 0
                                      ? _c(
                                          "v-col",
                                          { attrs: { cols: "12" } },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "caption d-block",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "brand.tile.viennaClasses"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _vm._l(
                                              _vm.brandData.viennaClasses,
                                              function (viennaClass, index) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: index,
                                                    staticClass: "d-inline",
                                                  },
                                                  [
                                                    viennaClass.description
                                                      ? _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              bottom: "",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "activator",
                                                                  fn: function (
                                                                    ref
                                                                  ) {
                                                                    var on =
                                                                      ref.on
                                                                    var attrs =
                                                                      ref.attrs
                                                                    return [
                                                                      _c(
                                                                        "v-chip",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {
                                                                              staticClass:
                                                                                "mr-1 mb-1",
                                                                              attrs:
                                                                                {
                                                                                  small:
                                                                                    "",
                                                                                  outlined:
                                                                                    "",
                                                                                },
                                                                            },
                                                                            "v-chip",
                                                                            attrs,
                                                                            false
                                                                          ),
                                                                          on
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "body-1",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  viennaClass.key
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  viennaClass.description
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        )
                                                      : _c(
                                                          "v-chip",
                                                          {
                                                            staticClass:
                                                              "mr-1 mb-1",
                                                            attrs: {
                                                              small: "",
                                                              outlined: "",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "body-1",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    viennaClass.key
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        )
                                      : _c("v-col", { attrs: { cols: "12" } }, [
                                          _c(
                                            "span",
                                            { staticClass: "caption d-block" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "brand.tile.viennaClasses"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "d-block text-truncate body-1 text--disabled",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "brand.tile.valueNotAvailable"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-card",
                          {
                            staticClass: "align-self-center",
                            attrs: { flat: "", tile: "", color: "transparent" },
                          },
                          [
                            _vm.fullBrandLogoUrl
                              ? _c("v-img", {
                                  staticClass: "ma-3",
                                  attrs: {
                                    "max-width": "200",
                                    src: _vm.fullBrandLogoUrl,
                                    contain: "",
                                  },
                                })
                              : _c(
                                  "v-icon",
                                  {
                                    staticClass: "ma-3",
                                    attrs: { size: "80" },
                                  },
                                  [_vm._v("fa-light fa-image-slash")]
                                ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm.brandId
              ? _c("SaveBrandBookmarkPopup", {
                  attrs: { brandId: _vm.brandId },
                  model: {
                    value: _vm.saveBookmarkPopupVisible,
                    callback: function ($$v) {
                      _vm.saveBookmarkPopupVisible = $$v
                    },
                    expression: "saveBookmarkPopupVisible",
                  },
                })
              : _vm._e(),
            _c("ConfirmationPopup", {
              attrs: {
                yes: "",
                cancel: "",
                question: "",
                title: _vm.$t("brand.tile.bookmarkRemoveConfirmation.title"),
                text: _vm.$t("brand.tile.bookmarkRemoveConfirmation.text"),
              },
              on: { yes: _vm.removeFromBookmarks },
              model: {
                value: _vm.deleteBookmarkConfirmationPopupVisible,
                callback: function ($$v) {
                  _vm.deleteBookmarkConfirmationPopupVisible = $$v
                },
                expression: "deleteBookmarkConfirmationPopupVisible",
              },
            }),
          ],
      _c("PaywallOverlay", {
        attrs: {
          hasFeature: _vm.loading || !_vm.hidden,
          requiredLogin: !_vm.userAccount,
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }